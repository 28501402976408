<template>
  <d-grid-card-wrapper>
    <q-card-section>
      <div class="grid-item__row">
        <div class="grid-item__title">
          {{ item.colsMap['agent'].label }}
        </div>
        <div class="grid-item__value">
          {{ item.row.agent.username }}
        </div>
      </div>
      <template v-for="prop in simpleProps">
        <div :key="prop.name" class="grid-item__row">
          <div class="grid-item__title">
            {{ prop.label }}
          </div>
          <div class="grid-item__value">
            {{ item.row[prop.name] }}
          </div>
        </div>
      </template>
    </q-card-section>
  </d-grid-card-wrapper>
</template>

<script>
import { DGridCardWrapper } from '@/features/grid-card-wrapper';

export default {
  components: { DGridCardWrapper },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    simpleProps: ({ item }) => item.cols.filter((el) => el.name !== 'agent'),
  },
};
</script>
