<template>
  <div
    class="q-pa-xs col-xs-12 col-sm-6 col-md-4"
    :style="isSelected ? 'transform: scale(0.95);' : ''"
  >
    <q-card :class="isSelected ? 'bg-grey-2' : ''" class="grid-item">
      <slot />
    </q-card>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="scss">
.grid-item {
  color: black;
  &__row {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__title {
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;

    &_inline {
      opacity: 1;
    }
  }

  &__value {
    font-size: 13px;
  }
}
</style>
