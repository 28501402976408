<template>
  <q-page padding class="fit column">
    <d-loader v-if="loading" />
    <div class="full-width">
      <slot
        v-bind="{
          loading,
          find,
          selectedData,
          removeHandler,
          data,
          setLoading,
          filtersEntity,
        }"
      />
    </div>
  </q-page>
</template>

<script>
import DLoader from '@/shared/ui/loader';
import { debounce, dialogs, notify } from '@/shared/utils';

export default {
  name: 'ViewCard',
  components: { DLoader },
  props: {
    fetchFunction: {
      type: Function,
      required: true,
    },
    removeFunction: {
      type: Function,
      default: () => null,
    },
    removeConfirmTitle: {
      type: String,
      default: () => 'Are you sure you want to delete the entity?',
    },
    removeSuccessTitle: {
      type: String,
      default: () => 'Success',
    },
    debounceTimeout: {
      type: Number,
      default: () => 500,
    },
    filters: {
      type: Object,
      default: () => null,
    },
    pagination: {
      type: Object,
      default: () => null,
    },
    filterTransformer: {
      type: Function,
      default: null,
    },
    readonlyData: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    loading: false,
    data: [],
    selectedData: [],
    filtersEntity: vm.filters,
    paginationEntity: vm.pagination,
    onUpdateFilters: () => null,
  }),

  created() {
    if (this.filters) {
      this.onUpdateFilters = debounce(this.find, this.debounceTimeout);
    }
  },

  async mounted() {
    await this.find();
  },

  methods: {
    setLoading(value) {
      this.loading = value;
    },
    async find(sort = {}) {
      try {
        this.loading = true;

        const { data } = await this.fetchFunction({
          filter: {
            ...(this.filterTransformer
              ? this.filterTransformer(this.filtersEntity)
              : this.filtersEntity),
          },
          sort,
          limit: { ...this.paginationEntity },
        });
        this.data = this.readonlyData ? Object.freeze(data) : data;
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    removeHandler(id) {
      dialogs
        .confirmDialog({
          message: this.removeConfirmTitle,
        })
        .onOk(async () => {
          if (id) await this.removeItem(id);
          else {
            await this.removeSelectedItems();
          }
        });
    },

    async removeItem(id) {
      if (!this.removeFunction) throw Error('remove function is not passed!');
      try {
        this.loading = true;
        await this.removeFunction(id);
        await this.find();
        notify.success(this.removeSuccessTitle);
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async removeSelectedItems() {
      if (!this.removeFunction) throw Error('remove function is not passed!');
      try {
        this.loading = true;
        const promises = this.selectedData.map((el) =>
          this.removeFunction(el.id)
        );
        await Promise.all(promises);
        this.selectedData = [];
        await this.find();
        notify.success(this.removeSuccessTitle);
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
