<template>
  <q-table
    v-if="!isBodySlot"
    v-bind="tableProps"
    :pagination.sync="pagination"
    @update:selected="onSelection"
  >
    <template v-slot:top>
      <slot name="top" />
    </template>
    <template v-slot:header-selection="scope">
      <slot name="header-selection" v-bind="scope">
        <q-checkbox
          v-model="scope.selected"
          :disable="isDisableHeaderSelection"
        />
      </slot>
    </template>
    <template v-slot:body-selection="scope">
      <q-checkbox
        v-model="scope.selected"
        :disable="isDisabledSelection(scope)"
      />
    </template>
    <template v-for="col in columns" #[getSlotName(col.name)]="props">
      <slot :name="`body-cell-${col.name}`" :props="{ ...props }">
        <q-td :props="props">
          {{ props.value }}
        </q-td>
      </slot>
    </template>
    <template v-slot:body-cell-action-btns="props">
      <slot name="body-cell-action-btns" :props="{ ...props }" />
    </template>
    <template v-slot:item="props">
      <slot name="grid-item" :props="props" />
    </template>
  </q-table>
  <q-table
    v-else
    ref="table"
    v-bind="tableProps"
    :expanded.sync="expanded"
    :pagination.sync="pagination"
    @update:selected="onSelection"
  >
    <template v-slot:body="props">
      <slot name="body" v-bind="{ ...props, expandRow }" />
    </template>
    <template v-slot:item="props">
      <slot name="grid-item" :props="props" />
    </template>
  </q-table>
</template>

<script>
export default {
  name: 'DVirtualTable',
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    withPagination: {
      type: Boolean,
      default: () => false,
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => [128, 256, 512, 1024],
    },
    withActionBtns: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: String,
      default: () => undefined,
    },
    selected: {
      type: Array,
      default: () => undefined,
    },
    isBodySlot: {
      type: Boolean,
      default: () => false,
    },
    isDisableHeaderSelection: {
      type: Boolean,
      default: () => false,
    },
    isDisabledSelection: {
      type: Function,
      default: () => false,
    },
    virtualScroll: {
      type: Boolean,
      default: true,
    },
  },
  data: (vm) => {
    let rowsPerPage = 0;

    if (vm.withPagination && vm.virtualScroll) {
      rowsPerPage = 512;
    } else if (vm.withPagination && !vm.virtualScroll) {
      rowsPerPage = 30;
    }

    return {
      expanded: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage,
        sortBy: null,
      },
    };
  },

  watch: {
    pagination: {
      handler: function (val, oldVal) {
        if (!Object.entries(val).every(([key, val]) => oldVal[key] === val)) {
          this.$emit('on-pagination', {
            ...val,
          });
        }
      },
    },
  },

  computed: {
    localColumns: ({ columns, withActionBtns }) =>
      withActionBtns
        ? [
            ...columns,
            {
              name: 'action-btns',
              field: 'actionBtns',
              label: 'Actions',
            },
          ]
        : columns,
    tableProps: (ctx) => ({
      grid: ctx.grid,
      class: ctx.virtualScroll ? 'virtual-scroll-table' : '',
      'virtual-scroll': ctx.virtualScroll,
      'rows-per-page-options': ctx.withPagination
        ? ctx.rowsPerPageOptions
        : [0],
      'virtual-scroll-sticky-size-start': 48,
      'row-key': 'id',
      data: ctx.items,
      columns: ctx.localColumns,
      selection: ctx.selection,
      selected: ctx.selected,
    }),
  },

  methods: {
    expandRow(rowKey) {
      if (this.expanded.includes(rowKey)) {
        this.$refs.table.setExpanded([]);
      } else {
        this.$refs.table.setExpanded([rowKey]);
      }
    },
    onSelection(data) {
      this.$emit('update:selected', data);
    },
    getSlotName(name) {
      return `body-cell-${name}`;
    },
  },
};
</script>

<style lang="scss">
.virtual-scroll-table {
  /* height or max-height is important */
  height: 63em;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #fff;
  } /* bg color is important for th; just specify one */

  thead tr th {
    position: sticky;
    z-index: 1;
  }
  /* this will be the loading indicator */
  thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
