<template>
  <d-view-card
    ref="view"
    :fetch-function="balanceController.getBalances"
    :pagination="pagination"
    :filters="filters"
    :filter-transformer="clearFilters"
  >
    <template #default="{ loading, find, data, filtersEntity }">
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card v-if="!isAgent" class="full-width">
          <q-card-section class="row q-col-gutter-sm">
            <d-user-select
              v-model="filtersEntity.agentId"
              label="Agent"
              dense
              class="col-md-4 col-6"
              :multiple="false"
              use-input
              emit-value
              map-options
              filled
              :options="agents"
            />
            <d-select-filtered
              multiple
              label="Currency"
              class="col-md-4 col-6"
              clearable
              filled
              dense
              emit-value
              map-options
              option-value="id"
              option-label="name"
              :options="currencyDictionary"
              :disable="loading"
              v-model="filtersEntity.agentCurrency"
              hint=""
            />
            <q-input
              dense
              filled
              v-model="filtersEntity.date"
              clearable
              label="Date"
              class="col-md-4 col-6"
              mask="####-##-##"
              hint="Click on the icon. Date format YYYY-MM-DD"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-calendar" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="filtersEntity.date" mask="YYYY-MM-DD">
                      <q-btn v-close-popup label="Ok" color="primary" flat />
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              :disable="loading"
              outline
              color="primary"
              label="Report"
              @click="onReportHandler"
            />
            <q-btn
              :disable="loading"
              color="primary"
              label="Apply"
              @click="find"
            />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-balance-table
            :grid="$q.screen.lt.md"
            :items="data"
            @on-pagination="onPaginationHandler"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DBalanceTable } from '@/features/balance-table';
import { DSelectFiltered } from '@/features/select-filtered';
import DUserSelect from '@/features/user-select';
import DViewCard from '@/layouts/view-card';
import {
  balanceController,
  currencyController,
  usersController,
} from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils, notify, objectUtils } from '@/shared/utils';
import withReportLoader from "@/shared/mixins/withReportLoader";

export default {
  components: {
    DSelectFiltered,
    DViewCard,
    DBalanceTable,
    DUserSelect,
  },
  mixins: [
    withReportLoader,
  ],
  data: () => ({
    agents: [],
    currencyDictionary: [],
    balanceController: balanceController(authUtils.getRoleByHierarchy()),
    filters: {
      agentId: null,
      agentCurrency: [],
      date: '',
    },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: false,
    },
    sort: {},
  }),
  computed: {
    isAgent: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT,
  },
  async mounted() {
    try {
      const primaryRole = authUtils.getRoleByHierarchy();
      if (!this.isAgent) {
        const { data } = await usersController(primaryRole).agentsForBalance();
        this.agents = data;
      }

      const currencyCall =
        primaryRole === ROLES.ROLE_ADMIN
          ? currencyController(primaryRole).getCurrency
          : currencyController(primaryRole).getCurrencyForPaymentRequisites;

      const { data } = await currencyCall();
      this.currencyDictionary = data.map((item) => ({
        ...item,
        name: `${item.name} (${item.code})`,
      }));
    } catch (e) {
      notify.error();
    }
  },
  methods: {
    resetSortState() {
      this.sort = {};
    },
    async onPaginationHandler({ rowsPerPage, sortBy, descending }) {
      this.pagination.maxResults = rowsPerPage;

      this.resetSortState();
      if (sortBy) {
        this.$set(this.sort, `${sortBy}`, descending ? 'desc' : 'asc');
      }

      await this.$refs.view.find(this.sort);
    },
    clearFilters: objectUtils.removeEmptyValuesRecursively,

    onReportHandler() {
      this.callPromptForEmail().onOk(async (email) => {
        try {
          await this.balanceController.applyReport({
            filter: this.clearFilters(this.filters),
            email,
          });
          notify.success('Report request sent successfully');
        } catch (e) {
          notify.error('Something went wrong while sending a report request.');
        }
      });
    },
  },
};
</script>
