export const columns = [
  {
    name: 'agent',
    field: (row) => row.agent.username,
    label: 'Agent for the cd',
  },
  {
    name: 'currency',
    field: ({ currency }) =>
      currency ? `${currency.name} (${currency.code})` : '',
    label: 'Currency',
  },
  {
    name: 'currentBalance',
    field: 'currentBalance',
    label: 'Agent balance for the cd',
    sortable: true,
  },
  {
    name: 'incomingBalance',
    field: 'incomingBalance',
    label: 'Incoming balance for the cd',
    sortable: true,
  },
  {
    name: 'refillOrderAmount',
    field: 'refillOrderAmount',
    label: 'Amount of deposits for the cd',
    sortable: true,
  },
  {
    name: 'payoutOrderAmount',
    field: 'payoutOrderAmount',
    label: 'Amount of withdrawals for the cd',
    sortable: true,
  },
  {
    name: 'refillOrderAgentFeeAmount',
    field: 'refillOrderAgentFeeAmount',
    label: 'Amount of commissions for deposits for the cd',
    sortable: true,
  },
  {
    name: 'payoutOrderAgentFeeAmount',
    field: 'payoutOrderAgentFeeAmount',
    label: 'Amount of commissions for withdrawal for the cd',
    sortable: true,
  },
  {
    name: 'currentPrepayments',
    field: 'currentPrepayments',
    label: 'Prepayments made for the cd',
    sortable: true,
  },
];
