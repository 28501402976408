import { validations } from '@/shared/utils';

export default {
  methods: {
    callPromptForEmail() {
      return this.$q.dialog({
        title: 'Report',
        message: 'Enter your email to send the report',
        cancel: true,
        prompt: {
          model: '',
          isValid: validations.validateEmail,
        },
      });
    },
  },
};
