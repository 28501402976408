export default {
  computed: {
    btnClass:
      ({ $q }) =>
      (isFirst = false) => {
        return {
          'q-mr-sm': isFirst && $q.screen.gt.sm,
          'q-mb-sm': isFirst && $q.screen.lt.md,
          'full-width': $q.screen.lt.md,
        };
      },
  },
};
